/*
 *  $Labels
 *
 */

.label {
    line-height: inherit;
    font-weight: 400
}
.label-default {
    .label-variant(@label-default-bg);
    color: @text-color;
    border: 1px solid @border-color;
}
.label-primary {
    .label-variant(@label-primary-bg);
    color: #fff;
}
.label-success {
    .label-variant(@label-success-bg);
}
.label-info {
    .label-variant(@label-info-bg);
}
.label-warning {
    .label-variant(@label-warning-bg);
}
.label-danger {
    .label-variant(@label-danger-bg);
}