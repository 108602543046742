/*
 * Image $Gallery
 *
 */

.superbox {
    & > ul {
        list-style: none;
        padding-left: 0;
    }
    & > ul > li {
        display: block;
        .rounded(@border-radius-base);
        margin-bottom: @size-base;
    }
    .gallery-description {
        display: block;
        background-color: #fff;
        padding: @padding-base-horizontal;
        border-radius: 0 0 @border-radius-base @border-radius-base;
        .title {
            display: block;
            text-transform: uppercase;
        }
    }
}

.gallery-loader {
    .opacity(1);
    display: block;
    background-color: fade(#fff, 80%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 999;
    height: 100%;
}

.portfolio-ajax {
    display: none;
    position: relative;
    .portfolio-container {
        padding-top: 50px;
        background-color: #fff;
    }
    .porfolio-controls {
        background-color: #fff;
        height: 50px;
        z-index: 99999;
        padding: @padding-base-horizontal;
        a {
            width: 33.3333%;
            display: block;
            float: left;
            vertical-align: middle;
            &.prev-portfolio-item {
                text-align: left;
            }
            &.next-portfolio-item {
                text-align: right;
            }
            &.close-view {
                text-align: center;
            }
            &.disabled {
                color: darken(#fff, 40%);
                ;
            }
        }
    }
    .project-media {
        text-align: center;
        margin-bottom: 30px;
    }
    .portfolio-content {
        padding: @padding-base-horizontal;

    }
    &.loading {
        
        .portfolio-container {
            .opacity(0);
        }
    }
    &.loaded {
        .gallery-loader {
            .opacity(0);
            display: none;
        }
    }
}
@media screen and (min-width: @screen-sm-min) {
    .portfolio-ajax {
        .portfolio-container {
            width: 100%;
            height: 100%;
            position: absolute;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    .portfolio-ajax {
        .porfolio-controls {
            height: 50px;
            width: 100%;
        }
    }
}