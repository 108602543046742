/*
 *  Demo specific styles.
 *
 * These can be removed
 */

.demo .slider.slider-vertical {
    height: 175px
}
.bs-demo .btn {
    margin-bottom: 5px
}
.tool-button .btn {
    margin-right: 5px
}
.demo-button .btn {
    min-width: 115px;
    margin-bottom: 5px;
    margin-right: 5px;
}
.demo > .dropdown {
    float: left;
}
.demo > .dropdown > .dropdown-menu {
    position: static;
    display: block;
    margin-bottom: 5px;
}
.demo-button2 button {
    margin: 0 5px 10px 0;
}
.fontawesome-list a {
    display: block;
    margin-left: 30px;
    height: 32px;
    color: @text-color;

font-size: 1em;
    line-height: 32px;
}
.fontawesome-list .fa {
    position: absolute;
    top: 9px;
    left: 20px;
    .transition(all 150ms linear);
}
.themify-icons .fa-hover {
    display: block;
    padding-left: 45px;
    height: 32px;
    color: @text-color;

font-size: 1em;
    line-height: 32px;
}
.themify-icons span {
    position: absolute;
    top: 9px;
    left: 20px;
    .transition(all 150ms linear);
}
.fontawesome-list a:hover .fa,
.themify-icons .fa-hover:hover span {
    top: 5px;
    color: @brand-success;

font-size: 1.5em;
}
.form-horizontal.bordered-group {
    .form-group {
        border-bottom: 1px solid #E7EBF3;
        padding-bottom: 15px;
    }
    .form-group:last-child {
        border-bottom: 1px solid transparent;
        padding: 0;
    }
}
.switcha > div {
    float: left;
}
.services .service {
    margin: 0 30px 25px;
    text-align: center;
}
.service-icon {
    margin: 0 auto 15px;
    width: 60px;
    line-height: 60px;
    font-size: 30px;
    color: #D6D6D6;
    .rounded(50%);
    &.bg-color {
        color: #fff;
    }
    &.huge {
        width: 100px;
        line-height: 100px;
        background-color: @brand-default;

    }
    &.left {
        float: left;
        margin: 0;
    }
}