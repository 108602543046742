/*
 * $Off Screen Menu
 *
 */

@media (max-width: @screen-xs-max) {
    html, body {
        height: 100%;
    }
    .offscreen {
        overflow: hidden;
    }
    .app {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .app.move-right,
    .app.move-left {
        .main-content .fffix {
            position: static;
        }
        & > .header {
            z-index: 0;
        }
    }
    .layout,
    .header {
        -webkit-backface-visibility: hidden;
        position: relative;
        width: 100%;
        .transition(transform 300ms ease 0s);
    }
    .layout:before,
    .layout:after {
        content: " ";
        display: table;
    }
    .layout:after {
        clear: both;
    }
    .offscreen-left {
        -webkit-backface-visibility: hidden;
        width: @sidebar-width-sm;
        height: 100%;
        overflow: auto;
        box-sizing: content-box;
        -webkit-overflow-scrolling: touch;
        .translate(-100%, 0);
        .translate3d(-100%, 0, 0);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
    }
    .offscreen-left * {
        -webkit-backface-visibility: hidden;
        -webkit-transform: translate3d(0,0,0);
    }
    .offscreen-right {
        -webkit-backface-visibility: hidden;
        width: @sidebar-width-sm;
        height: 100%;
        overflow: auto;
        box-sizing: content-box;
        -webkit-overflow-scrolling: touch;
        .translate(100%, 0);
        .translate3d(100%, 0, 0);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
    }
    .offscreen-right * {
        -webkit-backface-visibility: hidden;
    }
    .move-left > .layout,
    .move-left > .header {
        .translate(@sidebar-width-sm, 0);
        .translate3d(@sidebar-width-sm, 0, 0);
        .transition(transform 300ms ease);
    }
    .move-right > .layout,
    .move-right > .header {
        .translate(-@sidebar-width-sm, 0);
        .translate3d(-@sidebar-width-sm, 0, 0);
        .transition(transform 300ms ease);
    }
    .offscreen > .layout {
        position: absolute;
        top: 0;
        bottom: 0;
        overflow: visible;
    }
    .no-csstransforms .offscreen-left {
        left: -@sidebar-width-sm;
    }
    .no-csstransforms .offscreen-right {
        right: -@sidebar-width-sm;
    }
    .no-csstransforms .move-right > .layout,
    .no-csstransforms .move-right > .header {
        right: @sidebar-width-sm;
    }
    .no-csstransforms .move-left > .layout,
    .no-csstransforms .move-left > .header {
        left: @sidebar-width-sm;
    }
    .move-left .exit-offscreen,
    .move-right .exit-offscreen {
        -webkit-backface-visibility: hidden;
        cursor: pointer;
        display: block;
        position: absolute;
        background: rgba(255, 255, 255, 0.1);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
}